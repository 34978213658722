import type { ReactNode } from "react";
import { EditableButton, type EditableButtonProps } from "../components/forms/EditableButton.tsx";
import { WModal } from "../components/modal/WModal.tsx";
import { cn } from "../utils/classNames.ts";

export interface AdminModalButtonProps extends Omit<EditableButtonProps, "label" | "action"> {
    label: ReactNode;
    isDeleted?: boolean | null;
}

export function AdminModalButton({ label, children, isDeleted, ...buttonProps }: AdminModalButtonProps) {
    return isDeleted ? (
        <div className={cn(isDeleted && "line-through")}>{label}</div>
    ) : (
        <WModal
            button={
                <EditableButton action={undefined} {...buttonProps}>
                    {label}
                </EditableButton>
            }
        >
            {children}
        </WModal>
    );
}
